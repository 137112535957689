<template>
  <div class="benefit">
    <navbar bg="transparent" />
    <div class="dashboard">
      <div class="dashboard-item">
        <div class="label">总收益</div>
        <div class="money">{{ userInfo.income }}</div>
      </div>
      <button class="apply-btn" @click="$router.push('benefit/withdraw')">
        申请提现
      </button>
      <div class="bottom">
        <div class="dashboard-item">
          <div class="label">已提现</div>
          <div class="money">{{ userInfo.income - userInfo.can_withdraw }}</div>
        </div>
        <div class="dashboard-item">
          <div class="label">未提现</div>
          <div class="money">{{ userInfo.can_withdraw }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="teams-list"> -->
    <van-list
      v-model="loading"
      class="list-content"
      :finished="finished"
      finished-text="没有更多了"
      @load="fetchList"
    >
      <div v-for="item in resultList" :key="item.id" class="list-item">
        <div class="date" style="width: 25%">
          {{ formatTime(item.ctime, "YYYY-MM-DD\nhh:mm:ss") }}
        </div>
        <div class="desc" style="width: 25%">
          <div class="nick">{{ item.real_name }}</div>
          <div class="goods">{{ item.goods_name }}</div>
        </div>
        <div class="price" style="width: 25%">{{ item.income }}</div>
      </div>
    </van-list>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { List } from "vant";
import apis from "@/apis/index";
import Navbar from "@/components/h5/Navbar";
import dayjs from "dayjs";

// const mockData = new Array(30).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   real_name: "名字1",
//   mobile: "18573309100",
//   vip_level: [0, 1][index % 2]
// }));

export default {
  name: "Benefit",
  components: {
    "van-list": List,
    Navbar
  },
  data() {
    return {
      page: 1,
      finished: false,
      loading: false,
      resultList: []
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  mounted() {
    // this.fetchList();
  },
  methods: {
    async fetchList() {
      try {
        const {
          data: { user_income_list, max_page }
        } = await apis.userIncome({ page: this.page });
        this.page += 1;
        if (this.page >= max_page) {
          this.finished = true;
        }
        this.resultList = [...this.resultList, ...user_income_list];
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
      this.loading = false;
    },
    refreshList() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    },
    formatTime(time, format = "YYYY-MM-DD") {
      return dayjs(time).format(format);
    }
  }
};
</script>

<style lang="scss">
.van-list__loading {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.benefit {
  // margin: 20px;
  // padding: 20px;
  box-sizing: border-box;
  // height: calc(100% - 90px);

  .dashboard {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0 auto;
    padding: 60px 0;
    box-sizing: border-box;
    height: 580px;
    width: 1045px;
    background-color: #fff;
    border-radius: 10px;

    .dashboard-item {
      // width: 330px;
      // height: 120px;
      flex: none;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      color: #333;
      position: relative;

      .money {
        font-size: 80px;
        font-weight: bold;
        margin-top: 38px;
      }

      .label {
        font-size: 44px;
      }
    }

    .bottom {
      margin-top: 55px;
      display: flex;
      align-items: center;
      width: 100%;

      .dashboard-item {
        width: 50%;

        .money {
          font-size: 40px;
          color: #666;
          margin-top: 32px;
        }

        .label {
          font-size: 44px;
        }
      }
    }

    .apply-btn {
      margin-top: 50px;
      flex: none;
      width: 305px;
      height: 100px;
      border: 1px solid #8c4134;
      border-radius: 50px;
      background-color: transparent;
      color: #8c4134;
    }
  }

  .list-content {
    padding: 0 20px;
    margin: 40px;
    background-color: #fff;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    flex-flow: column nowrap;

    .list-item {
      flex: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 190px;
      color: #666666;
      border-bottom: 1px solid #f0f0f0;
      // padding: 0 20px;

      .date {
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 1.4;
        text-align: center;
      }

      .desc {
        .nick {
          font-size: 44px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }

        .goods {
          font-size: 40px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-top: 20px;
        }
      }

      .price {
        // align-self: flex-end;
        width: 162px;
        height: 38px;
        font-size: 56px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        text-align: right;
        padding-right: 20px;
      }
    }
  }
}
</style>
